<div class="stars hb-gradient-bg align-items-center d-flex">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <img class="image-404" src="assets/images/error-404.png" alt="error-img">
            </div>
            <div class="col-md-12 text-center">
                <a routerLink="/" class="btn btn-lg btn-gradient-primary btn-square mt-30">GO BACK HOME</a>
            </div>
        </div>
    </div>
    <div class="objects">
        <img class="object_rocket" src="assets/images/vinus.png" width="40px">
        <div class="earth-moon">
            <img class="object_earth" src="assets/images/sun.png" width="100px">
            <img class="object_moon" src="assets/images/saturn.png" width="80px">
        </div>
        <div class="box_astronaut">
            <img class="object_astronaut" src="assets/images/astro.png" width="140px">
        </div>
    </div>
    <div class="glowing_stars">
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>
        <div class="star"></div>

    </div>
</div>