<app-navbar></app-navbar>

<div class="transition-none">
    <section class="title-hero-bg" style="background-image: url(assets/images/title-bg/title-bg-2.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="page-title text-center white-color">
              <h1 class="raleway-font font-300">Blog Posts</h1>
              <div class="breadcrumb mt-20">
                  <!-- Breadcrumb Start -->
                      <ul>
                        <li><a routerLink="/home-nine">Home</a></li>
                        <li>Blog Posts</li>
                      </ul>
                  <!-- Breadcrumb End -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</div>

<section class="white-bg">
    <div class="container">
      <div class="row blog-style-01">
        <div *ngFor="let blog of blogs" class="col-md-4 col-sm-4 col-xs-12 mb-30">
          <div class="post">
            <div class="post-img"> 
              <img class="img-responsive" src="assets/images/post/{{blog.img}}" alt=""/> 
            </div>
            <div class="post-info all-padding-40">
              <h3><a routerLink="/blog-grid.html">{{blog.title}}</a></h3>
              <h6>{{blog.date}}</h6>
              <hr>
              <p class="mt-10"> <span> <a class="extras-wrap" href="javascript:void(0);"><i class="icofont icofont-chat"></i><span>{{blog.comment}}</span></a> <span class="extras-wrap"><i class="icofont icofont-clock-time"></i><span>{{blog.time}}</span></span> </span> </p>
              <a class="readmore" href="javascript:void(0);"><span>Read More</span></a> </div>
          </div>
        </div>
      </div>

      <div class="row mt-50">
        <div class="col-md-12">
            <div class="text-center">
              <div class="pagination text-uppercase dark-color justify-content-center">
                    <ul>
                        <li><a href="javascript:void(0);"><i class="icofont icofont-long-arrow-left mr-5 xs-display-none"></i> Prev</a></li>
                        <li class="active"><a href="javascript:void(0);">1</a></li>
                        <li><a href="javascript:void(0);">2</a></li>
                        <li><a href="javascript:void(0);">3</a></li>
                        <li><a href="javascript:void(0);">Next <i class="icofont icofont-long-arrow-right ml-5 xs-display-none"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    </div>
</section>

